@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
.body {
	background-color: #95c2de;
	height: calc(100vh - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.mainbox {
	background-color: #95c2de;
	margin: auto;
	height: 600px;
	width: 600px;
	position: relative;
}

.err {
	color: #ffffff;
	font-family: "Nunito Sans", sans-serif;
	font-size: 11rem;
	position: absolute;
	left: 20%;
	top: 8%;
}

.far {
	position: absolute;
	font-size: 8.5rem;
	left: 42%;
	top: 15%;
	color: #ffffff;
}

.err2 {
	color: #ffffff;
	font-family: "Nunito Sans", sans-serif;
	font-size: 11rem;
	position: absolute;
	left: 68%;
	top: 8%;
}

.msg {
	text-align: center;
	font-family: "consolas", sans-serif;
	font-size: 2rem;
	position: absolute;
	left: 16%;
	top: 45%;
	width: 75%;
}

.a {
	text-decoration: none;
	color: white;
}

.a:hover {
	text-decoration: underline;
}
