@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
.map {
	overflow: hidden;
	height: 500px;
	width: 100%;

	background-color: white;

	padding: 1rem;
	margin-top: 16px;

	border-radius: 20px;
	box-shadow: -1px -1px 68px -9px rgba(255, 255, 255, 1);
}

@media screen and (max-width: 768px) {
	.map {
		height: 400px;
		width: 100vw;
	}
}

.map .leaflet-container {
	height: 100%;
}

.info-container {
	width: 150px;
}

.info__logo {
	border-radius: 8px;
}
.info__logo img {
	display: inline-block;
	height: 40px;

	padding-right: 0;
	border: 0;
	vertical-align: middle;
}

.info__name {
	margin-top: 10px;
	font-size: 20px;
	font-weight: bold;
	color: #555;
}

.info__address {
	font-size: 16px;
	margin-top: 5px;
}

.body {
	background-color: #95c2de;
	height: calc(100vh - 80px);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.mainbox {
	background-color: #95c2de;
	margin: auto;
	height: 600px;
	width: 600px;
	position: relative;
}

.err {
	color: #ffffff;
	font-family: "Nunito Sans", sans-serif;
	font-size: 11rem;
	position: absolute;
	left: 20%;
	top: 8%;
}

.far {
	position: absolute;
	font-size: 8.5rem;
	left: 42%;
	top: 15%;
	color: #ffffff;
}

.err2 {
	color: #ffffff;
	font-family: "Nunito Sans", sans-serif;
	font-size: 11rem;
	position: absolute;
	left: 68%;
	top: 8%;
}

.msg {
	text-align: center;
	font-family: "consolas", sans-serif;
	font-size: 2rem;
	position: absolute;
	left: 16%;
	top: 45%;
	width: 75%;
}

.a {
	text-decoration: none;
	color: white;
}

.a:hover {
	text-decoration: underline;
}

.blocks {
	height: calc(100vh - 80px);
	width: 100vw;
	overflow: hidden;
	--primary: #fff;
	min-height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	background: rgba(246, 194, 73, 0.8);
}

/**
  * infinity-path
  *
  * @author jh3y
*/
.infinity-path {
	--size: 120;
	--speed: 0.65;
	height: calc(var(--size) * 1px);
	position: relative;
	width: calc(var(--size) * 1px);
}
.infinity-path > div {
	height: calc(var(--size) * 1px);
	width: calc(var(--size) * 1px);
	border-radius: 100%;
	border: calc(var(--size) / 4 * 1px) solid rgba(0, 0, 0, 0.05);
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation-duration: calc(var(--speed) * 1s);
	animation-duration: calc(var(--speed) * 1s);
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-name: infinity-spin;
	animation-name: infinity-spin;
	-webkit-transform: translate(calc(var(--translate) * 1%), 0)
		translate(calc(var(--translate-2) * 1px), 0);
	transform: translate(calc(var(--translate) * 1%), 0)
		translate(calc(var(--translate-2) * 1px), 0);
}
.infinity-path > div:before {
	content: "";
	height: calc(var(--size) / 4 * 1px);
	width: calc(var(--size) / 4 * 1px);
	border-radius: 100%;
	background: var(--primary);
	position: absolute;
	top: 50%;
	animation: infinity-vanish calc(var(--speed) * 2s) infinite reverse steps(1);
	-webkit-transform: translate(
		calc(var(--translate-2) * 2px),
		calc(var(--translate) * 1%)
	);
	transform: translate(
		calc(var(--translate-2) * 2px),
		calc(var(--translate) * 1%)
	);
}
.infinity-path > div:nth-of-type(1) {
	--translate: -50;
	--translate-2: calc(var(--size) / 8);
}
.infinity-path > div:nth-of-type(1):before {
	right: 0;
}
.infinity-path > div:nth-of-type(2) {
	--translate: 50;
	--translate-2: calc(var(--size) / 8 * -1);
	-webkit-animation-delay: calc(var(--speed) * 1s);
	animation-delay: calc(var(--speed) * 1s);
	animation-direction: reverse;
}
.infinity-path > div:nth-of-type(2):before {
	left: 0;
	-webkit-transform: translate(calc(var(--size) / 4 * -1px), -50%);
	transform: translate(calc(var(--size) / 4 * -1px), -50%);
	-webkit-animation-direction: normal;
	animation-direction: normal;
}

@-webkit-keyframes infinity-vanish {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@keyframes infinity-vanish {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}
@-webkit-keyframes infinity-spin {
	from {
		-webkit-transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
		transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
	}
	to {
		-webkit-transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
		transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
	}
}
@keyframes infinity-spin {
	from {
		-webkit-transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
		transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
	}
	to {
		-webkit-transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
		transform: translate(calc(var(--translate) * 1%), 0)
			translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
	}
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

